import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {withTranslation} from "react-i18next";
import PortfolioTwoCardsWithImage from "components/cards/PortfolioTwoCardsWithImage";
import WithMeta from "../utils/WithMeta";

function Jobs({t}) {
    return (
        <>
            <WithMeta
                title={`Owneat | ${t('header.jobs')}`}
                description={"Nous recherchons notre prochaine pépite."}
                url={"https://owneat.fr/jobs"}
            />
            <AnimationRevealPage>
                <Header/>
                <PortfolioTwoCardsWithImage/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
}

export default withTranslation()(Jobs);