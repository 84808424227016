import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

import HomePage from "HomePage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "pages/ContactUs";
import AboutUs from "pages/AboutUs";
import FAQ from "pages/FAQ";
import Job from "pages/Jobs";
import JobDetails from "pages/JobDetails";
import AllJobs from "pages/AllJobs";
import Pricing from "pages/Pricing";
import TermsOfService from "./pages/TermsOfService";
import {HelmetProvider} from "react-helmet-async";

export default function App() {

  return (
    <HelmetProvider>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/jobs" element={<Job />} />
          <Route path="/jobs/all" element={<AllJobs />} />
          <Route path="/jobs/:id" element={<JobDetails />} />
          <Route path="/terms" element={<TermsOfService/>} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}