import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import {withTranslation} from "react-i18next";
import WithMeta from "../utils/WithMeta";

function ContactUs({t}) {
    return (
        <>
            <WithMeta
                title={`Owneat | ${t('header.contact')}`}
                description={"Contactez-nous pour demander une démo ou poser une question."}
                url={"https://owneat.fr/contact"}
            />
            <AnimationRevealPage>
                <Header/>
                <ContactUsForm/>
                <Footer/>
            </AnimationRevealPage>
        </>

    );
}

export default withTranslation()(ContactUs);