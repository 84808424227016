import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enEN from "./locales/en.json"
import frFR from "./locales/fr.json"


const resources = {
    en: {
        translation: enEN,
    },
    fr: {
        translation: frFR,
    }
};

const lng = navigator.language.split("-")[0];
const fallbackLng = 'en';

i18n.use(initReactI18next).init({
    resources,
    lng: lng || fallbackLng, // Use the user's preferred language if available, otherwise use the fallback language
    fallbackLng, // Set fallback language for cases when the selected language is not available
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;