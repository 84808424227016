import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {withTranslation} from "react-i18next";
import ThreePlans from "components/pricing/ThreePlans";
import WithMeta from "../utils/WithMeta";

function Pricing({t}) {
    return (
        <>
            <WithMeta
                title={`Owneat | ${t('header.pricing')}`}
                description={"Une tarification adaptée à la taille de votre restaurant."}
                url={"https://owneat.fr/pricing"}
            />
            <AnimationRevealPage>
                <Header/>
                <ThreePlans/>
                <Footer/>
            </AnimationRevealPage>
        </>

    );
}

export default withTranslation()(Pricing);