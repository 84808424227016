import { css } from "styled-components/macro"; //eslint-disable-line

const defaultPlans = [{
    name: "Pack Découverte",
    price: ["€49", ".99 HT"],
    fees: false,
    feesAmount: "",
    duration: "Mensuel",
    mainFeature: "Pour optimiser l'expérience client avec une consultation experte.",
    features: [
        "Consultation dynamique du menu digitalisée",
        "Gestion intégrale du menu",
        "Création de cartes multiples",
        "Gestion des suggestions",
        "Gestion des heures d'ouvertures",
        "Gestion des tables",
        "Mise en avant d'évènements (afterworks, fermetures exceptionnelles...)",
    ],
},
{
    name: "Pack Essentiel",
    price: ["€74", ".99 HT"],
    fees: false,
    feesAmount: "",
    duration: "Mensuel",
    mainFeature: "Pour gérer les commandes à table avec simplicité et efficacité.",
    features: [
        "Consultation dynamique du menu digitalisée",
        "Commande personnalisée (cuisson, extras, etc...)",
        "Appel à un serveur pour un besoin spécifique (eau, pain, etc...)",
        "Réception des commandes et gestion du flux vers la cuisine",
        "Annulation",
        "Changement de table d'un client",
        "Gestion intégrale du menu",
        "Création de cartes multiples",
        "Gestion des suggestions",
        "Gestion des heures d'ouvertures",
        "Gestion des tables",
        "Mise en avant d'évènements (afterworks, fermetures exceptionnelles...)",
        "Gestion de ses serveurs"
    ],
    featured: true,
},
{
    name: "Pack Intégral",
    price: ["€99", ".99 HT"],
    fees: true,
    feesAmount: "0.7% / transaction",
    duration: "Mensuel",
    mainFeature: "Pour une solution tout-en-un de commande et paiement à table.",
    features: [
        "Consultation dynamique du menu digitalisée",
        "Commande personnalisée (cuisson, extras, etc...)",
        "Appel à un serveur pour un besoin spécifique (eau, pain, etc...)",
        "Répartition ludique de la note",
        "Paiement dématérialisé instantané entrainant un commissionnement / transaction",
        "Système de pourboire intégré",
        "Réception de Note de Frais disponible",
        "Réception des commandes et gestion du flux vers la cuisine",
        "Annulation",
        "Changement de table d'un client",
        "Remboursement",
        "Gestion intégrale du menu",
        "Création de cartes multiples",
        "Gestion des suggestions",
        "Gestion des heures d'ouvertures",
        "Gestion des tables",
        "Mise en avant d'évènements (afterworks, fermetures exceptionnelles...)",
        "Gestion de ses serveurs",
        "Gestion financière intégrée"
    ]
}];

const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, #63ea83 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

export { defaultPlans, highlightGradientsCss };