import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton";
import cards from "resources/offers";
import {useParams} from "react-router-dom";
import WithMeta from "../utils/WithMeta";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

function JobDetails(props) {
    let {id} = useParams();
    const job = cards.find(e => e.id === id);

    return (
        <>
            <WithMeta
                title={`Owneat | ${job.title}`}
                description={job.title}
                url={`https://owneat.fr/jobs/${job.id}`}
            />
            <AnimationRevealPage>
                <Header/>
                <MainFeature1
                    subheading={<Subheading>{job.type} | {job.durationText} | {job.locationText}</Subheading>}
                    heading={job.title}
                    description={job.goal}
                    imageSrc={job.imageSrc}
                    primaryButtonText={"Postulez ici !"}
                    primaryButtonUrl="/contact"
                />
                <MainFeature1
                    subheading={<Subheading>Qui ?</Subheading>}
                    heading="La personne"
                    description={job.who}
                    imageSrc={job.imageSrc}
                    primaryButtonText={"Postulez ici !"}
                    primaryButtonUrl="/contact"
                    textOnLeft={false}
                />
                <MainFeature1
                    subheading={<Subheading>Les plus ?</Subheading>}
                    heading="Le bonus"
                    description={job.bonus}
                    imageSrc={job.imageSrc}
                    primaryButtonText={"Postulez ici !"}
                    primaryButtonUrl="/contact"
                />
                <Footer/>
            </AnimationRevealPage>
        </>
    );
}

export default withTranslation()(JobDetails);