import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import SingleCol from "components/faqs/SingleCol";
import {withTranslation} from "react-i18next";
import WithMeta from "../utils/WithMeta";

function FAQ({t}) {
    return (
        <>
            <WithMeta
                title={`Owneat | ${t('header.faq')}`}
                description={"Nous avons des réponses à toutes vos questions."}
                url={"https://owneat.fr/faq"}
            />
            <AnimationRevealPage>
                <Header/>
                <SingleCol/>
                <Footer/>
            </AnimationRevealPage>
        </>

    );
}

export default withTranslation()(FAQ);