import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import emailjs from "emailjs-com";
import { withTranslation } from "react-i18next";
import { RingLoader } from "react-spinners";
import { RiCheckLine } from "react-icons/ri";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 flex items-center justify-center`;

function TwoColContactUsWithIllustrationFullForm({
  t,
  subheading = t('contact.subheading'),
  heading = <>{t('contact.contactUs')}<wbr /></>,
  description = "",
  submitButtonText = t('contact.submitButtonText'),
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState(submitButtonText);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
      .then((result) => {
        setIsLoading(false);
        setIsSubmitted(true);
      }, (error) => {
        setIsLoading(false);
      });

    e.target.reset()
  };

  const changeSubmitButtonText = async (e) => {
    e.preventDefault();
    setIsLoading(false);
    setIsSubmitted(false);
    setButtonText(t('contact.submitButtonText'));
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} onSubmit={handleOnSubmit} onFocus={changeSubmitButtonText} netlify>
              <Input type="email" name="email" placeholder={t('contact.form.email')} required />
              <Input type="text" name="name" placeholder={t('contact.form.name')} required />
              <Input type="tel" name="phone" placeholder={t('contact.form.phone')} required />
              <Textarea name="message" placeholder={t('contact.form.message')} required />
              <SubmitButton type="submit">
                {isLoading ? (
                  <RingLoader size={20} color={"#ffffff"} />
                ) : isSubmitted ? (
                  <>
                    {t('contact.form.sent')}
                    <RiCheckLine style={{ marginLeft: "0.5rem" }} />
                  </>
                ) : (
                  buttonText
                )}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default withTranslation()(TwoColContactUsWithIllustrationFullForm);
