import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import {withTranslation} from "react-i18next";
import WithMeta from "../utils/WithMeta";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

function AboutUs({t}) {
    return (
        <>
            <WithMeta
                title={`Owneat | ${t('header.about')}`}
                description={"Owneat est la solution digitale complète à destination des restaurateurs, centrée sur la qualité de l'expérience de leurs clients."}
                url={"https://owneat.fr/about"}
            />
            <AnimationRevealPage>
                <Header/>
                <MainFeature1
                    subheading={<Subheading>Owneat ?</Subheading>}
                    heading={t('aboutUsPage.solution')}
                    description={
                        <>
                            {t('aboutUsPage.destiny')}{" "}
                            <HighlightedText>{t('aboutUsPage.toStreamline')}</HighlightedText>{" "}
                            {t('aboutUsPage.yourCustomers')}{" "}
                            <HighlightedText>{t('aboutUsPage.yourRestaurant')}</HighlightedText>
                        </>
                    }
                    imageSrc="https://images.unsplash.com/photo-1497644083578-611b798c60f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    primaryButtonText={t('button.contact')}
                    primaryButtonUrl="/contact"
                />
                <MainFeature1
                    subheading={<Subheading>{t('aboutUsPage.problem')}</Subheading>}
                    heading={t('aboutUsPage.mealAtRestaurant')}
                    description={
                        <>
                            {t('aboutUsPage.twoKeyMoments')}{" "}
                            <HighlightedText>{t('aboutUsPage.orderTaking')}</HighlightedText>{" "}
                            {t('aboutUsPage.choice')}{" "}
                            <HighlightedText>{t('aboutUsPage.payment')}</HighlightedText>{" "}
                            {t('aboutUsPage.waiting')}
                        </>
                    }
                    primaryButtonText={t('button.contact')}
                    primaryButtonUrl="/contact"
                    imageSrc="https://images.unsplash.com/photo-1485182708500-e8f1f318ba72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1510&q=80"
                    textOnLeft={false}
                />
                <MainFeature1
                    subheading={<Subheading>{t('aboutUsPage.ourVision')}</Subheading>}
                    heading={t('aboutUsPage.atTheTable')}
                    description={
                        <>
                            {t('aboutUsPage.scan')}{" "}
                            <HighlightedText>{t('aboutUsPage.webApp')}</HighlightedText>{" "}
                            {t('aboutUsPage.beAbleTo')}{" "}
                            <HighlightedText>{t('aboutUsPage.checkTheMenu')}</HighlightedText>{" "}
                            {t('aboutUsPage.severalLanguages')}{" "}
                            <HighlightedText>{t('aboutUsPage.placeHisOrder')}</HighlightedText>{" "}
                            {t('aboutUsPage.followItsProgress')}{" "}
                            <HighlightedText>{t('aboutUsPage.pay')}</HighlightedText>{" "}
                            {t('aboutUsPage.endHisMeal')}
                        </>
                    }
                    primaryButtonText={t('button.contact')}
                    primaryButtonUrl="/contact"
                    imageSrc="https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    textOnLeft={true}
                />
                <MainFeature1
                    subheading={<Subheading>{t('aboutUsPage.ourSolution')}</Subheading>}
                    heading={
                        <>
                            {t('aboutUsPage.timeSaving')}{" "}
                            <HighlightedText>{t('aboutUsPage.toSave')}</HighlightedText>
                        </>
                    }
                    description={t('aboutUsPage.eachMeal')}
                    primaryButtonText={t('button.contact')}
                    primaryButtonUrl="/contact"
                    imageSrc="https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80"
                    textOnLeft={false}
                />
                <TeamCardGrid
                    subheading={<Subheading>{t('aboutUsPage.ourTeam')}</Subheading>}
                />
                <Footer/>
            </AnimationRevealPage>
        </>
    );
}

export default withTranslation()(AboutUs);