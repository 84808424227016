import Frontend from "images/offers/frontend.jpg";
import Backend from "images/offers/backend.jpg";
import Sales from "images/offers/sales.jpg";

const cards = [
  {
    id: "key-account-manager-internship",
    imageSrc:
      Sales,
    company: "Owneat",
    type: "Stage",
    title: "Stage Sales | Key Account Manager",
    durationText: "6 mois",
    locationText: "Sophia Antipolis",
    disabled: false,
    goal: (
      <div>
        <p>
          L'objectif du stage est de participer activement au développement commercial de l'entreprise tout en permettant au stagiaire d'acquérir une expérience concrète dans le domaine des ventes et du management de comptes clés.
        </p>
        <br></br>
        <ul>
          <li>• Prospection et développement d'un portefeuille clients dans le secteur spécifié</li>
          <li>• Gestion des relations avec les clients existants, identification des besoins et proposition de solutions adaptées</li>
          <li>• Participation à l'élaboration et à la mise en œuvre de stratégies commerciales</li>
          <li>• Suivi des ventes, reporting régulier et analyse des performances</li>
          <li>• Collaboration étroite avec les équipes internes pour assurer la satisfaction client et la réalisation des objectifs de vente</li>
        </ul>
        <br></br>
        <p>
          Ce stage offre une opportunité unique de développer ses compétences en vente, de travailler en étroite collaboration avec des professionnels du secteur et de contribuer activement à la croissance de l'entreprise.
        </p>
      </div>
    ),
    who: (
      <div>
        <p>
          Nous recherchons un étudiant motivé et dynamique, doté d'un excellent sens du relationnel et d'une forte orientation commerciale.
        </p>
        <br></br>
        <p>
          Une première expérience dans la vente ou le service client serait un plus, mais n'est pas obligatoire. Ce qui compte avant tout, c'est votre passion pour la vente, votre capacité à convaincre et votre envie d'apprendre.
        </p>
        <br></br>
        <p>
          Si vous êtes ambitieux, autonome et que vous aimez relever les défis, ce stage est fait pour vous !
        </p>
      </div>
    ),
    bonus: `Ambiance de travail conviviale, opportunités d'apprentissage continu, événements d'intégration réguliers.`
  },
  {
    id: "flutter-frontend-software-engineer-internship",
    imageSrc:
      Frontend,
    company: "Owneat",
    type: "Stage",
    title: "Stage Ingénieur Logiciel Frontend Flutter",
    durationText: "6 mois",
    locationText: "Sophia Antipolis",
    disabled: false,
    goal: (
      <div>
        <p>
          L’objectif du stage consiste à participer à toutes les étapes du développement du projet tout en donnant
          l’opportunité au stagiaire de monter en compétences et en polyvalence sur différents sujets.
        </p>
        <br></br>
        <ul>
          <li>• Participation aux ateliers techniques pour répondre à des besoins métiers/fonctionnels</li>
          <li>• Compréhension des User Story, découpage en Technical Tasks, Sizing</li>
          <li>• Développements applicatifs côté Frontend dans un contexte d’utilisation de la librairie/pattern BLoC en state manager, avec beaucoup de programmation réactive (notamment SignalR)</li>
          <li>• Utilisation de l’architecture micro-services du Backend, prise en compte des problématiques de performance, de confort et de maintenance, le tout user-oriented</li>
          <li>• Définition du plan de test, mise en œuvre de l’automatisation des tests dans un contexte CI/CD</li>
          <li>• Participation aux Reviews de code, implication d’un Senior pour progresser et évoluer</li>
          <li>• Mise à jour de la Documentation Technique existante (DAL, Models, Rules & Choices, …)</li>
        </ul>
        <br></br>
        <p>
          Intégré au sein d’une équipe pluridisciplinaire, en fonctionnement agile, vous participerez
        </p>
        <br></br>
        <ul>
          <li>• Aux Cérémonies agiles (Daily, Planning, Review, Retrospective…)</li>
          <li>• Aux Bilatérales, mensuellement (échanges avec le Chef de Projet et le Manager)</li>
        </ul>
        <br></br>
        <p>
          L’encadrement de qualité, technique comme fonctionnel, sera un gage de sécurité et de satisfaction.
        </p>
      </div>
    ),
    who: (
      <div>
        <p>
          Nous recherchons pour ce stage un étudiant en dernière année de cycle ingénieur spécialisé en informatique
          et passionné par ce domaine, souhaitant s’orienter vers du développement Frontend à haute valeur ajoutée,
          avec un réel intérêt pour l’utilisateur final et donc intégrant by-design des réflexions UI/UX.
        </p>
        <br></br>
        <p>
          Une ou plusieurs expériences précédentes (monde professionnel, projets personnels, projets scolaires) dans
          les éléments présentés ci-dessus est nécessaire à une prise en main rapide et efficace du projet, à la
          compréhension de ses contraintes et à une montée en compétences sereine.
        </p>
        <br></br>
        <p>
          Veille technologique, curiosité, volonté de progresser, motivation et autonomie sont des prérequis
          nécessaires à l’intégration dans l’équipe projet et au bon déroulement de celui-ci.
        </p>
      </div>
    ),
    bonus: `Bonne humeur au quotidien, croissants offerts, babyfoots endiablés, afterworks aux petits oignons…`,
  },
  {
    id: "csharp-dotnet-backend-software-engineer-internship",
    imageSrc:
      Backend,
    company: "Owneat",
    type: "Stage",
    title: "Stage Ingénieur Logiciel Backend C# .NET",
    durationText: "6 mois",
    locationText: "Sophia Antipolis",
    disabled: true,
    goal: (
      <div>
        <p>
          L’objectif du stage consiste à participer à toutes les étapes du développement du projet tout en donnant
          l’opportunité au stagiaire de monter en compétences et en polyvalence sur différents sujets
        </p>
        <br></br>
        <ul>
          <li>
            • Prise en compte de l’architecture micro-services du Backend, choix de la philosophie de
            développement, prise en compte des problématiques de performance, de maintenance by-design
          </li>
          <li>• Participation aux ateliers techniques pour répondre à des besoins métiers/fonctionnels</li>
          <li>• Compréhension des User Story, découpage en Technical Tasks, Sizing</li>
          <li>
            • Développements applicatifs côté Backend, participation aux développements sur les aspects Base de
            Données et DevSecOps (c’est un atout majeur que vous pouvez mettre en avant !)
          </li>
          <li>• Définition du plan de test, mise en œuvre de l’automatisation des tests dans un contexte CI/CD</li>
          <li>• Participation aux Reviews de code, implication d’un Senior pour progresser et évoluer</li>
          <li>• Mise à jour de la Documentation Technique existante (DAL, Models, Rules & Choices, …)</li>
        </ul>
        <br></br>
        <p>
          Intégré au sein d’une équipe pluridisciplinaire, en fonctionnement agile, vous participerez
        </p>
        <br></br>
        <ul>
          <li>• Aux Cérémonies agiles (Daily, Planning, Review, Retrospective…)</li>
          <li>• Aux Bilatérales, mensuellement (échanges avec le Chef de Projet et le Manager)</li>
        </ul>
        <br></br>
        <p>
          L’encadrement de qualité, technique comme fonctionnel, sera un gage de sécurité et de satisfaction.
        </p>
      </div>
    ),
    who: (
      <div>
        <p>
          Nous recherchons pour ce stage un étudiant en dernière année de cycle ingénieur spécialisé en informatique
          et passionné par ce domaine, souhaitant s’orienter vers du développement Backend respectant la CLEAN
          Architecture en écosystème principalement Microsoft (C# .NET 7, SQL Server 2022, …).
        </p>
        <br></br>
        <p>
          Une ou plusieurs expériences précédentes (monde professionnel, projets personnels, projets scolaires) dans
          les éléments présentés ci-dessus est nécessaire à une prise en main rapide et efficace du projet, à la
          compréhension de ses contraintes et à une montée en compétences sereine.
        </p>
        <br></br>
        <p>
          Veille technologique, curiosité, volonté de progresser, motivation et autonomie sont des prérequis
          nécessaires à l’intégration dans l’équipe projet et au bon déroulement de celui-ci.
        </p>
      </div>
    ),
    bonus: `Bonne humeur au quotidien, croissants offerts, babyfoots endiablés, afterworks aux petits oignons…`,
  }
];

export default cards;