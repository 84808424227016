import {Helmet} from "react-helmet-async";

function WithMeta({
                      children,
                      title,
                      description,
                      url
                  }) {
    return (
        <Helmet
            prioritizeSeoTags
            async={false}
        >
            <title>{title}</title>
            <link rel="canonical" href={url}/>
            <meta name="description" content={description}/>
            <meta itemprop="name" content="Owneat | Everything from your seat"/>
            <meta itemprop="description" content={description}/>
            <meta property="og:description" content={description}/>
            <meta property="og:url" content={url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
    );
}

export default WithMeta;