import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "components/misc/Headings";
import WithMeta from "../utils/WithMeta";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({headingText = "Conditions Générales d'Utilisation"}) => {
    return (
        <>
            <WithMeta
                title={"Owneat | CGU"}
                description={"Conditions Générales d'Utilisation."}
                url={"https://owneat.fr/terms"}
            />
            <AnimationRevealPage>
                <Header/>
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>{headingText}</Heading>
                        </HeadingRow>
                        <Text>
                            <h1>CONDITIONS GÉNÉRALES D'UTILISATION</h1>

                            <p><strong>Dernière mise à jour : 27 juin 2024</strong></p>

                            <p>
                                Les présentes Conditions Générales d'Utilisation (les « Conditions
                                ») régissent l'utilisation des produits et services proposés par
                                OWNEAT, une société par actions simplifiée au capital social de
                                10,000 €, dont le siège social est situé à PARC DE SOPHIA ANTIPOLIS
                                SPACE 9 2323 CHEMIN SAINT BERNARD 06220 VALLAURIS, immatriculée au
                                Registre du Commerce et des Sociétés d'Antibes sous le numéro 929
                                956 571 (« nous » ou « Owneat »). Ces Conditions
                                s'appliquent à notre site internet, notre application web (« l'App
                                Owneat ») ainsi qu'à tous nos autres services en ligne (les «
                                Services »).
                            </p>

                            <p>
                                Notre mission chez Owneat est d'améliorer votre expérience en
                                tant que consommateur, notamment en offrant une solution de
                                commande et de paiement intégrée dans tous les Établissements
                                utilisant nos Services, tels que les restaurants, bars, hôtels,
                                etc.
                            </p>

                            <p>
                                En utilisant l'App Owneat ou nos Services, vous acceptez
                                préalablement, expressément et sans réserve les présentes
                                Conditions. Si vous n'acceptez pas ces Conditions, vous ne pouvez
                                pas utiliser l'App Owneat ni nos Services.
                            </p>

                            <p>
                                Nous nous réservons le droit de modifier ces Conditions à tout
                                moment, principalement pour nous conformer aux évolutions de
                                l'App Owneat ou aux développements juridiques et techniques
                                pertinents. Toutes les modifications seront publiées sur notre
                                site web (https://owneat.fr/) ou dans l'App Owneat. Il est de
                                votre responsabilité de consulter régulièrement les Conditions
                                mises à jour avant d'utiliser l'App Owneat ou nos Services.
                            </p>

                            <h2>Article 1 – Compte Utilisateur</h2>

                            <p>Aucun compte utilisateur n'est requis sur l'App Owneat pour
                                effectuer des commandes et des paiements.</p>

                            <h2>Article 2 – Sauvegarde des moyens de paiement</h2>

                            <p>Owneat propose la possibilité de sauvegarder les détails de votre
                                carte lors de l'utilisation de l'App Owneat. Cette fonctionnalité
                                est gérée par nos prestataires de services de paiement, garants de
                                la sécurité et de la confidentialité de vos moyens de paiement.</p>

                            <p>La sauvegarde de vos moyens de paiement n'est pas obligatoire
                                mais facilite les paiements futurs via l'App Owneat.</p>

                            <h2>Article 3 – Utilisation de l'App Owneat et de nos Services</h2>

                            <p>En scannant nos QR codes ou en utilisant notre solution, un
                                Établissement peut vous permettre d'accéder à tout ou partie de
                                nos Services, incluant mais ne se limitant pas à :</p>

                            <ul>
                                <li>Accéder à un menu et passer une commande ("Commande et
                                    Paiement");
                                </li>
                                <li>Visualiser l'addition en temps réel;</li>
                                <li>Payer l'addition plus rapidement sans attendre le serveur et/ou
                                    faire la queue au point de vente;
                                </li>
                                <li>Laisser un pourboire;</li>
                                <li>Obtenir un reçu électronique de votre paiement.</li>
                            </ul>

                            <p>Veuillez suivre les instructions de l'App Owneat pour utiliser
                                nos Services. Notez que nous ne fournissons pas de services de
                                paiement, ceux-ci étant gérés par l'émetteur de votre moyen de
                                paiement (carte de crédit, monnaie électronique, etc.). De plus,
                                l'App Owneat ne constitue pas un instrument de paiement.</p>

                            <p>L'utilisation de nos Services peut entraîner des frais, calculés
                                sur la base du montant payé. Ces frais permettent l'accès à nos
                                Services, notamment la visualisation en temps réel de l'addition,
                                le paiement instantané, la division rapide de l'addition et le
                                téléchargement du reçu.</p>

                            <p>Si les frais sont applicables, vous pouvez choisir de payer
                                directement par carte bancaire à l'Établissement concerné pour
                                éviter ces frais.</p>

                            <h3>3.1 Accès au menu et Commande en temps réel</h3>

                            <p>Vous pouvez consulter le menu d'un Établissement et passer
                                commande directement via l'App Owneat. Vous êtes responsable de
                                vérifier que le menu vous convient et de confirmer auprès du
                                personnel de l'Établissement.</p>

                            <p>Pour utiliser ce service, vous devrez fournir un pseudonyme et
                                une adresse email, nécessaire pour recevoir votre reçu.</p>

                            <h3>3.2 Paiement rapide de l'addition</h3>

                            <p>Vous pouvez payer tout ou partie de l'addition via l'App Owneat,
                                que vous ayez commandé via l'App ou directement auprès de
                                l'Établissement. Vous pouvez accéder à votre addition en scannant
                                notre QR Code. Assurez-vous de vérifier les détails avant de
                                procéder au paiement.</p>

                            <p>Les moyens de paiement acceptés varient selon l'Établissement et
                                peuvent inclure carte de débit/crédit, paiement en ligne, etc.</p>

                            <p>En effectuant un paiement, vous acceptez de fournir des
                                informations précises et de respecter les conditions générales de
                                votre prestataire de paiement. Nous ne sommes pas responsables des
                                obligations liées à ces paiements.</p>

                            <p>Une fois le paiement effectué sur l'App Owneat, l'Établissement
                                concerné reçoit les informations suivantes : date et heure du
                                paiement, type de carte (visa/mastercard), 4 derniers chiffres de
                                la carte. Votre adresse email n'est jamais transmise à
                                l'Établissement.</p>

                            <p>Vous pouvez récupérer votre reçu sur l'App Owneat ou le recevoir
                                par email, automatiquement avec ApplePay ou GooglePay.</p>

                            <p>En cas d'échec du paiement via l'App Owneat, vous devrez payer
                                directement auprès de l'Établissement.</p>

                            <h3>3.3 Pourboire</h3>

                            <p>En payant via l'App Owneat, vous avez la possibilité de laisser
                                un pourboire pour le personnel de l'Établissement. Ce pourboire
                                peut être ajouté à votre addition avant de confirmer votre
                                paiement. Owneat ne perçoit aucun pourcentage sur les pourboires
                                laissés par les utilisateurs de l'App.</p>

                            <h2>Article 4 – Conditions de Refus de Service</h2>

                            <p>Owneat se réserve le droit de refuser l'accès à tout ou partie
                                de nos Services à tout moment et sans préavis, notamment en cas
                                d'activités suspectes, d'utilisation frauduleuse de l'App Owneat
                                ou de non-respect des présentes Conditions.</p>

                            <h2>Article 5 – Propriété Intellectuelle</h2>

                            <p>Owneat détient tous les droits de propriété intellectuelle
                                relatifs à l'App Owneat et à nos Services, y compris les
                                droits d'auteur, marques déposées, brevets, bases de données et
                                autres droits de propriété intellectuelle.</p>

                            <p>Sauf autorisation expresse d'Owneat, toute reproduction,
                                distribution, utilisation ou modification de l'App Owneat ou de
                                nos Services est strictement interdite.</p>

                            <h2>Article 6 – Limitation de Responsabilité</h2>

                            <p>Nous nous efforçons de fournir des Services de haute qualité,
                                mais nous ne garantissons pas que l'App Owneat sera toujours
                                accessible sans interruption ou sans erreur. En cas de problème
                                technique, veuillez contacter notre support client via
                                <a href="mailto:contact@owneat.fr"> contact@owneat.fr.</a></p>

                            <p>Dans les limites permises par la loi applicable, nous déclinons
                                toute responsabilité en cas de préjudice ou de dommage découlant
                                de l'utilisation de l'App Owneat ou de nos Services, y compris
                                les pertes de données, la perte de bénéfices, l'interruption
                                d'activité, etc.</p>

                            <h2>Article 7 – Droit Applicable et Juridiction Compétente</h2>

                            <p>Les présentes Conditions sont régies par le droit français. En
                                cas de litige, nous vous encourageons à nous contacter en
                                premier lieu pour tenter de résoudre tout différend
                                amiablement.</p>

                            <p>À défaut d'accord amiable, tout litige relatif à l'interprétation
                                ou à l'exécution des présentes Conditions sera soumis à la
                                compétence exclusive des tribunaux français.</p>

                            <p>Nous vous remercions d'avoir pris le temps de lire nos
                                Conditions Générales d'Utilisation. En cas de questions,
                                n'hésitez pas à nous contacter à l'adresse suivante :
                                <a href="mailto:contact@owneat.fr"> contact@owneat.fr.</a></p>
                        </Text>
                    </ContentWithPaddingXl>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
