import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {withTranslation} from "react-i18next";
import WithMeta from "./utils/WithMeta";

function HomePage({t}) {
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const imageCss = tw`rounded-4xl`;
    return (
        <>
            <WithMeta
                title={"Owneat | Everything from your seat"}
                description={"Owneat est la solution digitale complète à destination des restaurateurs, centrée sur la qualité de l'expérience de leurs clients."}
                url={"https://owneat.fr/"}
            />
            <AnimationRevealPage>
                <Hero
                    heading={
                        <>
                            {t('homepage.welcome')}{" "}
                            <HighlightedText>{t('Owneat')}</HighlightedText>
                        </>
                    }
                    description={t('homepage.presentation')}
                    imageSrc="https://www.avisto.com/wp-content/uploads/2022/10/AVISTO-OWNEAT-1.jpg"
                    imageCss={imageCss}
                    imageDecoratorBlob={true}
                    primaryButtonText={t('button.findOutMore')}
                    primaryButtonUrl="/contact"
                    watchVideoButtonText="Demo"
                />
                <MainFeature2
                    subheading=""
                    description={t('homepage.problematic')}
                    heading={
                        <>
                            {t('homepage.why')}{" "}
                            <HighlightedText>{t('homepage.chooseUs')}</HighlightedText>
                        </>
                    }
                    statistics={[
                        {
                            key: t('homepage.founded'),
                            value: "2023",
                        },
                        {
                            key: t('homepage.followers'),
                            value: "1100+"
                        },
                        {
                            key: t('homepage.hours'),
                            value: "3000+"
                        },
                        {
                            key: t('homepage.restaurants'),
                            value: "2+"
                        }
                    ]}
                    primaryButtonText={t('button.joinUs')}
                    primaryButtonUrl="/contact"
                    imageInsideDiv={false}
                    imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
                    imageCss={Object.assign(tw`bg-cover`, imageCss)}
                    imageContainerCss={tw`md:w-1/2 h-auto`}
                    imageDecoratorBlob={true}
                    imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
                    textOnLeft={false}
                />
                <Testimonial
                    subheading=""
                    heading={
                        <>
                            {t('homepage.they')}{" "}
                            <HighlightedText>{t('homepage.talkAboutUs')}</HighlightedText>
                        </>
                    }
                />
                <Footer/>
            </AnimationRevealPage>
        </>
    );
}

export default withTranslation()(HomePage);